<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn v-if="$nova.hasRight(right, 'add')"
                rounded
                color="primary"
                @click="
                  queriesDilog = true;
                  queriesObject = {};
                  error = {};
                "
                elevation="0"
                small
                class="mr-2 mt-1"
                ><v-icon class="mr-1">mdi-plus</v-icon>New query</v-btn
              >
              <lb-string
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <!-- <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              > -->
              <v-btn icon small class="ml-2" @click="refreshData()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="queriesHeader"
              :items="queriesData"
              hide-default-footer
              dense
              :items-per-page="-1"
              :search="seachList"
            >
              <template #[`item.no`]="{ item, index }">
                <tr>
                  <td>{{ index + 1 }}</td>
                </tr>
              </template>

              <template v-slot:[`item.stage`]="{ item }">
                <v-chip x-small label v-if="item.stage === 1" color="success"
                  >Close</v-chip
                >
                <v-chip x-small label v-else color="info">Open</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="
                        $nova.gotoLink({ path: '/helpdesk/queries/queriesview/' + item._id })
                      "
                      class="view-on-hover-item-d mx-0 px-0"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-eye-arrow-right-outline</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="queriesDilog" max-width="600" persistent>
      <v-card class="" :loading="queriesloading" :disabled="queriesloading" loader-height="2">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="queriesObject._id"
            >Update Query</span
          >
          <span class="subtitle-1 white--text" v-else>Add New Query</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="queriesDilog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                label="Query Title"
                v-model="queriesObject.issue"
                :error="errors['issue']"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-textarea
                v-model="queriesObject.description"
                label="Query (description)"
                :error="errors['description']"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            v-if="queriesObject._id"
            @click="productUpdate()"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="createQuerie()"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      right: "helpdesk",
      loading: false,
      errors: {},
      queriesObject: {},
      queriesDilog: false,
      queriesloading: false,
      queriesHeader: [
        { text: "No", value: "no", sortable: false },
        { text: "Querie", value: "issue", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Stage", value: "stage", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
      seachList: null,
      queriesData: [],
    };
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/helpdesk/queries/get")
        .then((dt) => {
          this.queriesData = dt.data.data;
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createQuerie() {
      this.queriesloading = true;
      this.queriesObject.modulename = this.$route.mata.modulename;
      this.axios
        .post("/v2/helpdesk/queries/add", { data: this.queriesObject })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
            this.$store.commit("sbSuccess", "New Query Added successfully");
            this.queriesDilog = false;
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Add Query");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.queriesloading = false;
        });
    },
    QuerieUpdate() {
      this.loading = true;
      this.queriesObject.modulename = this.$route.mata.modulename;
      this.axios
        .post("/v2/helpdesk/queries/edit/" + this.queriesObject._id, {
          data: this.queriesObject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
            this.$store.commit("sbSuccess", "New Query Added successfully");
            this.refreshData();
            this.queriesDilog = false;
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Add Query");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    conversation(item) {
      console.log(item);
    },
  },
};
</script>
